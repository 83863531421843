import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import Icon from '@svgs/logo_odawara_icon.svg'
import LogoBrandBg from "@svgs/logo_brand_bg.svg"

const DesignPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainDesign'>
      {/* CONTENTS */}

      <ComMain
        ttl="DESIGN"
        subttl="デザイン"
        caption="エントランス外観完成予想CG"
      >
        <StaticImage
          src="../assets/images/design/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/design/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>

      <section className='sec-concept'>
        <div className="bg-logo bg-logo1">
          <LogoBrandBg
            alt=""
          />
        </div>

        <div className="c-content">

          <div className="concept-wrap">
            <div className="txt-box comFeed">
              <div className="design-txt-box">
                <p className="lead">城下町の風情を感じる<br />小田原の邸宅。</p>
                <p className="txt">歴史と伝統ある城下町の⾵情を今に残す建設地界隈。<br />「銀座通り交差点」に⽴地する<br />「ル・サンク⼩⽥原栄町」は、<br />城下町の⾵趣を素材にしてその魅⼒を磨き上げ、<br />街並みに溶け込みながら地域全体とつながり、<br />⼩⽥原の魅⼒を⾼めていく建築として計画しています。</p>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-fasade'>
        <div className="bg-logo bg-logo2">
          <LogoBrandBg
            alt=""
          />
        </div>
        <div className="bg-logo bg-logo3">
          <LogoBrandBg
            alt=""
          />
        </div>

        <div className="c-content">

          <div className="mainimg-wrap">
            <div className="mainimg-box comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/design/fasade.png"
                  alt=""
                />
              </div>
            </div>
            <p className="c-note white">外観完成予想CG</p>
          </div>

          <div className="flex-wrap">
            <div className="flex-box-01 comFeed">

              <div className="list">
                <p className="ttl">日本建築に見られる屋根の水平ライン</p>
                <p className="txt">日本家屋の庇に倣い、最上階に庇を回したデザインを採り入れました。屋根の水平ラインが強調され、和の印象を醸し出します。</p>
                <div className="flex-img">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/design_01.jpg"
                      alt=""
                    />
                    <p className="c-cap cap">外観完成予想CG</p>
                  </div>
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/design_02.jpg"
                      alt=""
                    />
                    <p className="c-cap cap">image photo</p>
                  </div>
                </div>
              </div>
              <div className="list">
                <p className="ttl">横桟が印象的な手摺のデザイン</p>
                <p className="txt">バルコニーの手摺には横方向に桟を渡しました。日本建築によく見られる手摺のデザインを模して、宿場町らしい風景を創出しました。</p>
                <div className="flex-img">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/design_03.jpg"
                      alt=""
                    />
                    <p className="c-cap cap">外観完成予想CG</p>
                  </div>
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/design_04.jpg"
                      alt=""
                    />
                    <p className="c-cap cap">image photo</p>
                  </div>
                </div>
              </div>
              <div className="list">
                <p className="ttl">水平ラインを強調する出桁（だしげた）</p>
                <p className="txt">軒を深く前面に張り出した出桁造りは伝統的な町屋の建築方法。この特徴的な意匠を現代風にアレンジし、各住戸の玄関・バルコニー側に小庇を設けました。</p>
                <div className="flex-img">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/design_05.jpg"
                      alt=""
                    />
                    <p className="c-cap cap">外観完成予想CG</p>
                  </div>
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/design_06.jpg"
                      alt=""
                    />
                    <p className="c-cap cap">image photo</p>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="intro-wrap">

            <div className="flex-box archi-box comFeed">
              <div className="txt-box">
                <p className='ttl'>ARCHITECT</p>
                <p className='txt'>「ル・サンク小田原栄町」は周辺の町屋建築の特徴を踏襲した外観デザインとし、城下町・宿場町の賑わいを感じさせる風情ある街並みの形成を図る事を意識しました。また、駅からのアプローチとなる敷地南側と商店街が続く敷地東側の結節点に緑量豊かなオープンスペースを配し、人々に受け入れられる街のシンボルとなるようなデザインを目指しました。</p>
              </div>
              <div className="img-box">
                <div className="img">
                  <StaticImage
                    src="../assets/images/design/archi.jpg"
                    alt=""
                  />
                </div>
                <div className="txt-box txt-box2">
                  <p className='p1'>株式会社長谷工<br className='_sp' />コーポレーション<br /><br className='_sp' />エンジニアリング事業部 チーフ</p>
                  <p className='p2'>西山 隆<br className='_sp' /><span><span className='_pc'>／ </span>Takashi Nishiyama</span></p>
                </div>
              </div>
            </div>
            <div className="flex-box mtrl-box comFeed">
              <div className="txt-box">
                <p className='ttl'>MATERIAL</p>
                <p className='txt'>建物の表情に深みを与える天然石や木調の素材を厳選し、格調高い邸宅の佇まいを創出。いつまでも色褪せることのない美質を追求しています。</p>
                <p className='c-note note'>※掲載のマテリアル参考写真は同種サンプルを撮影したもので、実物と質感・色の見え方が異なる場合があります。</p>
              </div>
              <div className="img-box">
                <div className="img">
                  <StaticImage
                    src="../assets/images/design/material.jpg"
                    alt=""
                  />
                </div>
                <p className="c-cap cap">マテリアル参考写真</p>
              </div>
            </div>

          </div>

        </div>
      </section>


      <section className='sec-entrance'>

        <div className="c-content">

          <div className="txt-wrap">
            <div className="txt-box comFeed">
              <div className="design-txt-box">
                <p className="ttl"><span><Icon /></span>ENTRANCE DESIGN</p>
                <p className="lead">小田原らしさを継承する建築。</p>
                <p className="txt">国道255線に面するエントランスは縦のルーバーを連続的にデザイン。<br />水平方向に大胆に延びる庇は和的な屋根を現代風にアレンジしたもの。<br />カラーリングには木造建築を意識し、焦げ茶などの飴色に近い色調を採用し、落ち着きのある和モダンな佇まいを演出しました。</p>
              </div>
            </div>
          </div>

        </div>

        <div className="main-wrap">
          <div className="main-box comFeed">
            <div className="img">
              <StaticImage
                src="../assets/images/design/entrance_01.jpg"
                alt=""
              />
            </div>
            <p className="c-cap">エントランス外観完成予想CG</p>
          </div>
        </div>


        {/* <div className="c-content">
          <div className="com-note-wrap">
            <p className="c-note">※掲載の環境写真は、2021年10月に撮影したものです。<br />※掲載の距離表示については地図上の概測距離を、徒歩分数表示については80m＝1分として算出し、端数を切り上げしたものです。<br />※掲載の情報は 2022年1月時点のものです。<br />※掲載の完成予想CGは、計画段階の図面を基に描いたもので、外観・外構・植栽・仕上げ等は実際とは異なる場合があります。外観形状の細部や設備機器等は表現されておりません。またタイルや各種部材の質感や色等は実際とは異なります。行政指導及び施工上の理由等のため、今後変更となる場合があります。敷地内の植栽は育成に必要な環境の下で、竣工から一定期間を経た状態のものを想定して描いており、特定の季節を表したものではありません 。建設地周辺の建物や電柱等については、白線等で表現している部分がございます。周辺環境や景観は将来に渡り保証されるものではありません。予めご了承ください。<br />※「image photo」の表記がある写真は全てイメージです。</p>
          </div>
        </div> */}


      </section>

      <section className='sec-entrance-hall'>
        <div className="inner-wrap">

          <div className="c-content">

            <div className="flex-box">
              <div className="txt-wrap">
                <div className="txt-box comFeed">
                  <div className="design-txt-box">
                    <p className="ttl"><span><Icon /></span>ENTRANCE HALL</p>
                    <p className="lead">「ル・サンク⼩⽥原栄町」の<br />世界観を表現した共⽤空間。</p>
                    <p className="txt">「ル・サンク⼩⽥原栄町」では2名のアーティストによりエントランスホールをプロデュース。インテリアデザインとアート作品により、この邸宅ならではの独⾃の世界観を表現しました。⼩⽥原を誇らしく感じながら、豊かな時間をお過ごしいただける空間に仕⽴てています。</p>
                  </div>
                </div>
              </div>
              <div className="img-box comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/design/entrance_02.jpg"
                    alt=""
                  />
                  <p className="c-cap cap">集会室完成予想CG</p>
                </div>
              </div>
            </div>

          </div>

          <div className="main-wrap">
            <div className="main-box comFeed">
              <div className="img">
                <StaticImage
                  src="../assets/images/design/entrance_03.jpg"
                  alt=""
                />
              </div>
              <p className="c-cap">エントランスホール完成予想CG</p>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-design'>
        <div className="bg-logo bg-logo4">
          <LogoBrandBg
            alt=""
          />
        </div>
        <div className="bg-logo bg-logo5">
          <LogoBrandBg
            alt=""
          />
        </div>
        <div className="inner-wrap">

          {/* <div className="ttl-box ttl-1 comFeed">
            <p className='ttl'>INTERIOR DESIGN</p>
          </div> */}
          <div className="c-content">

            <div className="interior-box">
              <div className="flex-box">
                <div className="design-txt-box comFeed">
                  <div className="ttl-box ttl-1 comFeed">
                    <p className='ttl'>INTERIOR DESIGN</p>
                  </div>
                  <p className='lead'>「美しい歴史の余韻」と、<br />それを引き継ぐための<br className='_pc' />贅沢な余白や<br className='_sp' />ディティールを創作する。</p>
                  <p className='txt'>「本物であること」と「自然物と現代素材を掛け合わせること」を基準にインテリア素材を厳選。外から中へ引き込み、包み込まれるような光の照明計画を施した空間は、贅沢な余白とディティールから生まれる圧倒的な心地よさを演出します。</p>
                </div>
                <div className="prof-box comFeed">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/prof_01.jpg"
                      alt=""
                    />
                  </div>
                  <div className="txt-box">
                    <p className='p1'>インテリアデザイナー<br /><span>竹田純 </span>／ Jun Takeda<br />HYBE design team  代表</p>
                    <p className='p2'>アナログで古いものやハイテクで新しいもの、カジュアルやラグジュアリーなど、時間軸や価値観を超えてハイブリットな視点でデザイン創作を展開。レストラン・カフェ、商業施設やオフィスをはじめ家具等のプロダクト開発など、インテリアを通じて幅広くデザインを行っています。</p>
                  </div>
                </div>
              </div>
              <div className="img-box comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/design/prof_02.jpg"
                    alt=""
                  />
                  <p className="c-cap cap">素材イメージ</p>
                </div>
              </div>
            </div>

          </div>

          <div className="c-content">

            <div className="kamon-box">
              <div className="flex-box">
                <div className="design-txt-box comFeed">
                  <div className="ttl-box ttl-2 comFeed">
                    <p className='ttl'>KAMON DESIGN</p>
                  </div>
                  <p className='lead'>この街のこの住まいに<br className='_sp' />暮らす方々の<br className='_pc' />ストーリーが、<br className='_sp' />ひとつひとつ積み重なって<br className='_pc' />いきますように、という願いを込めて。</p>
                  <p className='txt'>作品名は「流転」。江戸末期文久年間の小田原を描き、デフォルメしたものを徐々に拡大しながら10段階に積層した作品で、一つの状態にとどまらず、移り変わっていく街をアクリルに表現。どの年代の方にも楽しんで頂けるようにと考えて制作しました。</p>
                </div>
                <div className="prof-box comFeed">
                  <div className="img">
                    <StaticImage
                      src="../assets/images/design/prof_03.jpg"
                      alt=""
                    />
                  </div>
                  <div className="txt-box">
                    <p className='p1'>京源三代目紋章上繪師<br /><span>波戸場承龍 </span>／ <br className='_sp' />Shoryu Hatoba<br />株式会社 京源 代表</p>
                    <p className='p2'>着物に家紋を手で描き入れる紋章上繪師としての技術を継承する一方、デジタル技術と融合させた「紋曼荼羅®」という技法を生み出す。森羅万象を描き出す職人兼デザイナーとして、あらゆる分野のデザインに挑戦し続けている。</p>
                  </div>
                </div>
              </div>
              <div className="img-box comFeed">
                <div className="img">
                  <StaticImage
                    src="../assets/images/design/prof_04.png"
                    alt=""
                  />
                  <p className="c-cap cap">※掲載の作品は2022年4月現在での予定作品となっており、今後変更になる場合があります。</p>
                </div>
              </div>
            </div>

          </div>

          <div className="c-content">
            <div className="com-note-wrap">
              <p className='c-note'>※掲載の完成予想CGは、計画段階の図面を基に描いたもので、外観・外構・植栽・仕上げ等は実際とは異なる場合があります。家具・照明器具・調度品等は、実際に設置されるものと異なる場合があります。外観形状の細部や設備機器等は表現されておりません。またタイルや各種部材の質感や色等は実際とは異なります。行政指導及び施工上の理由等のため、今後変更となる場合があります。敷地内の植栽は育成に必要な環境の下で、竣工から一定期間を経た状態のものを想定して描いており、特定の季節を表したものではありません 。建設地周辺の建物や電柱等については、白線等で表現している部分がございます。周辺環境や景観は将来に渡り保証されるものではありません。予めご了承ください。<br />※「image photo」の表記がある写真は全てイメージです。</p>
            </div>
          </div>

        </div>
      </section>

      {/* CONTENTS */}
    </main>
  )
}

export default DesignPage
